@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background-default {
  background-image: url('../public/assets/images/background.png');
}

/* @media (min-width: 441px) and (max-width: 768px)  {
  .background-default {
    background-image: url('../public/assets/images/bgMedium.svg');
    
  }
}
@media (min-width: 320px) and (max-width: 440px) {
  .background-default {
    background-image: url('../public/assets/images/background2.svg');
  }
} */

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1,
h2,
h3,
h4,
p,
a {
  font-family: "Space Grotesk", "sans-serif";
}



/* Add this to your global CSS file */
/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #051367;
  /* color of the scrollbar thumb */
  border-radius: 10px;
  border: 2px solid transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #1E3A8A;
  /* darker color of the scrollbar thumb on hover */
}

menu-lists:hover {
  background-color: #93959c;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  /* color of the scrollbar track */
  border-radius: 10px;
}

.mobile-scroll-space::after {
  content: '';
  display: block;
  width: 1px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .mobile-scroll-space::after {
    display: none;
  }
}


/* */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background: #FFFAEA !important;
  color: #141414 !important;
  position: fixed;
}

.css-1q39md6-MuiButtonBase-root-MuiButton-root {
  color: #141414 !important;
}

.hover-underlines {
  position: relative;
  display: inline-block;
}

.hover-underlines::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #141414;
  bottom: -3px;
  left: 0;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.hover-underlines:hover::after {
  transform: scaleX(1);
  transform-origin: bottom;
}

.MuiTypography-root {
  text-decoration: none !important
}

.menu-lists {
  color: black;
}


@media (max-width: 550px) {
  .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    opacity: 1;
    transform: none;
    transition: opacity 267ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 178ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 0px !important;
    left: 0px !important;
    transform-origin: 227px 0px;
    display: flow-root;
    width: 150%;
    justify-content: space-between;
    max-width: calc(100% - 0px) !important
  }
}

/*  */

body.menu-open {
  overflow: hidden;
}

.MuiMenu-paper {
  transition: transform 0.3s ease-in-out !important;
}

@media (max-width: 600px) {
  .MuiMenu-paper {
    width: 100% !important;
    max-width: 100% !important;
    height: 100vh !important;
    top: 0 !important;
    right: 0 !important;
    left: auto !important;
    transform: translateX(100%);
  }

  .MuiMenu-paper.MuiPopover-paper {
    transform: translateX(0) !important;
  }
}

.css-19r6kue-MuiContainer-root {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 20px !important;
  padding-right: 20px !important
}

.css-mf1cb5-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
}

.css-1dttb4z {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 16px !important;
}

.css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
  padding: 0px !important
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  display: flow-root;
  display: flex !important;
  align-items: center !important;
  min-height: 40px !important;
  padding-top: 8px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}